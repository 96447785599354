/* product left start */
const galleryNodes = document.querySelectorAll(".js-grid-gallery__content");
const showcaseCard = document.querySelectorAll(".showcase-card");
const descriptions = document.querySelectorAll(
    ".js-showcase-card__description"
);
const anchors = document.querySelectorAll(".js-showcase-card__expand");

const galleryOpts = {
    arrowNavigation: true,
};

const options = {
    arrowNavigation: true,
    onOpen: function () {
        document.body.style.overflow = "hidden";
    },
    onClose: function () {
        document.body.style.overflow = "visible";
    },
};

if (galleryNodes.length) {
    new LuminousGallery(galleryNodes, galleryOpts, options);
}

if (showcaseCard.length) {
    const cardSlider = new Swiper(".showcase-card-slider", {
        spaceBetween: 0,
        centeredSlides: false,
        loop: true,
        direction: "horizontal",
        loopedSlides: 3,
        resizeObserver: true,
    });
    const cardThumbs = new Swiper(".showcase-card-thumbs", {
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        slideToClickedSlide: true,
        direction: "horizontal",
        slidesPerView: 3,
    });
    cardSlider.forEach((el, i) => {
        el.controller.control = cardThumbs[i];
    });
    cardThumbs.forEach((el, i) => {
        el.controller.control = cardSlider[i];
    });

    anchors.forEach((el, i) => {
        el.addEventListener("click", () => {
            const expanded = descriptions[i].classList.contains(
                "showcase-card__description"
            );
            if (expanded) {
                descriptions[i].classList.remove("showcase-card__description");
                descriptions[i].classList.add(
                    "showcase-card__description--expand"
                );
                el.innerHTML = "Read less";
            } else {
                descriptions[i].classList.add("showcase-card__description");
                descriptions[i].classList.remove(
                    "showcase-card__description--expand"
                );
                el.innerHTML = "Read more";
            }
        });
    });
}
