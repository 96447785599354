document.querySelectorAll('.hero__scroll-down-trigger')
    .forEach(function(item, index){
        item.addEventListener('click', function(){
           let parentSection = item.closest('section.section-container');
           if(parentSection){
               let next = parentSection.nextElementSibling;
               next.scrollIntoView({behavior: 'smooth'});
           }
        });
});
