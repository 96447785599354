const _temp0 = require("../components/accordion/accordion.js");

const _temp1 = require("../components/footer/footer.js");

const _temp2 = require("../components/hero/hero.js");

const _temp3 = require("../components/nav/navigation.js");

const _temp4 = require("../components/showcasecard/cards.js");


module.exports = {
  "components":   {
    "accordion":     {
      "accordion": _temp0
    },
    "footer":     {
      "footer": _temp1
    },
    "hero":     {
      "hero": _temp2
    },
    "nav":     {
      "navigation": _temp3
    },
    "showcasecard":     {
      "cards": _temp4
    }
  }
}